import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class NotPageComponent extends Vue {

  public mounted() {
    this.$router.push('/404');
  }

  public serverPrefetch() {
    return new Promise((resolve, reject) => {
      return reject({ redirectTo: '/404' });
    });
  }
}

