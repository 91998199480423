import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';

@Component({})
export default class WorkRequestComponent extends Vue {
  public load = false;
  public link: any;

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Trabajar en Dunati Chile',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: '¿Quieres sumarte a nuestra misión? Conoce las distintas oportunidades laborales que ofrecemos. Sigamos ampliando los límites del diseño y creando un mundo mejor.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/trabaja-en-dunati`,
        },
        {
          property: 'og:title',
          content: 'Trabajar en Dunati Chile',
        },
        {
          property: 'og:description',
          content: '¿Quieres sumarte a nuestra misión? Conoce las distintas oportunidades laborales que ofrecemos. Sigamos ampliando los límites del diseño y creando un mundo mejor.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/trabaja-en-dunati`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/trabaja-en-dunati`
          }
        ]
      }
      */
    };
  }

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
    this.load = true;
  }

  public beforeDestroy() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public getUrl() {
    const link = window.location.href;
    return `https://podio.com/webforms/18590888/1251089?e=true#${link}`;
  }
}

